<template>
  <div class="container">
    <div class="flex-column">
      <div class="row">
        <div class="col-lg-4 mb-5 mb-xxl-8">
          <div class="card shadow-sm h-100">
            <div class="card-header align-items-center border-0 pt-9">
              <h3 class="fs-4 mb-0">Total Summary</h3>
            </div>
            <div class="card-body d-flex flex-column pt-5 pb-0">
              <div class="d-flex align-items-center justify-content-between mb-4">
                <div class="me-4">Subtotal</div>
                <div>{{ $n(subTotal, 'currency', 'en-CA') }}</div>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-4">
                <div class="me-4">HST 13%</div>
                <div>{{ $n(tax, 'currency', 'en-CA') }}</div>
              </div>
              <div class="d-flex align-items-center justify-content-between mb-4">
                <div class="me-4 fw-medium">Total</div>
                <div class="fs-5 fw-medium">
                  {{ state === 'Change Order' ? '100%' : $n(total, 'currency', 'en-CA') }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="payment-schedule" class="col-lg-8 mb-5 mb-xxl-8">
          <div class="card shadow-sm h-100">
            <div class="card-header align-items-center border-0 pt-9">
              <h3 class="fs-4 mb-0">Payment Schedule</h3>
            </div>
            <div v-if="paymentScheduleItems.length" class="card-body d-flex flex-column pt-0 pb-0">
              <div class="table-responsive">
                <!--begin::Table-->
                <table class="table">
                  <!--begin::Thead-->
                  <thead class="fs-10 fw-bold text-uppercase text-ls-sm">
                    <tr>
                      <th class="w-50px">Pct(%)</th>
                      <th>Milestone</th>
                      <th class="w-150px text-end">Amount($)</th>
                      <th class="min-w-10px w-10px text-end"></th>
                    </tr>
                  </thead>
                  <!--end::Thead-->
                  <!--begin::Tbody-->
                  <tbody>
                    <tr v-for="item in paymentScheduleItems" :key="item.id">
                      <td>
                        <input
                          v-model="item.attributes.percentage"
                          class="form-control form-control-solid fw-normal"
                          type="text"
                          @input="onPercentageChange"
                        />
                      </td>
                      <td>
                        <textarea-autosize
                          v-model="item.attributes.note"
                          class="form-control form-control-solid fw-normal"
                          rows="1"
                          :min-height="42"
                          :max-height="500"
                        />
                      </td>
                      <td class="text-nowrap">
                        <money
                          :value="priceFromPercentageWithHst(item.attributes.percentage)"
                          v-bind="moneyWithPrecision"
                          class="form-control form-control-solid fw-normal"
                          readonly
                        />
                      </td>
                      <td class="text-end align-middle">
                        <px-dropdown
                          :items="[
                            { title: 'Duplicate', action: () => duplicatePaymentScheduleItem(item.attributes) },
                            {
                              title: 'Delete',
                              disabled: paymentScheduleItems.length <= 1,
                              action: () => deletePaymentScheduleItem(item.id),
                            },
                          ]"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td class="text-center">{{ totalPercentage }}%</td>
                      <td />
                      <td class="text-nowrap text-center">{{ $n(subTotal + tax, 'currency', 'en-CA') }}</td>
                    </tr>
                  </tbody>
                  <!--end::Tbody-->
                </table>
                <!--end::Table-->
              </div>
            </div>
            <div v-if="totalPercentage !== 100" class="card-footer border-0">
              <div
                class="d-flex align-items-center notice bg-light-danger rounded border-warning border border-dashed p-3"
              >
                <span class="svg-icon svg-icon-3x svg-icon-danger me-4">
                  <inline-svg src="/assets/media/icons/duotune/general/gen044.svg" />
                </span>
                <span>
                  {{
                    state === 'Change Order' ? 'Due upon contract acceptance' : 'Payment schedule does not equal 100%'
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header align-items-center border-0 pt-9 px-0">
          <h3 class="fs-4 mb-0">Pricing & Payment Information</h3>
        </div>
        <div class="card-body pt-3 px-0">
          <px-editor
            v-if="isLoaded"
            ref="pricingAndPaymentInformationEditor"
            :content="normalizeString(pricingAndPaymentInformation)"
            @on-text-change="onPricingAndPaymentInformationUpdate"
          />
        </div>
      </div>

      <div class="card">
        <div class="card-header align-items-center border-0 pt-9 px-0">
          <h3 class="fs-4 mb-0">Terms, Conditions, and Warranties</h3>
        </div>
        <div class="card-body pt-3 px-0 pb-0">
          <px-editor
            v-if="isLoaded"
            ref="termsConditionsWarrantiesEditor"
            :content="normalizeString(termsConditionsWarranties)"
            @on-text-change="onTermsConditionsWarrantiesUpdate"
          />
        </div>
      </div>

      <!--begin::Actions-->
      <div class="d-flex flex-stack mt-8 pt-8 border-top">
        <!--begin::Wrapper-->
        <div class="me-4">
          <px-btn
            color="secondary"
            :in-process="prevInProcess"
            :disabled="prevInProcess"
            @click.native="() => goToPrev(updateValidation)"
          >
            Back
          </px-btn>
        </div>
        <!--end::Wrapper-->
        <!--begin::Wrapper-->
        <div class="ms-auto">
          <px-btn
            color="success"
            extended-classes="me-2"
            :in-process="updateInProcess"
            :disabled="updateInProcess"
            @click.native="() => onUpdate(updateValidation)"
          >
            Save
          </px-btn>
          <px-btn
            :in-process="nextInProcess"
            :disabled="nextInProcess"
            @click.native="() => goToNext(updateValidation)"
          >
            Next
          </px-btn>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Actions-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { nanoid } from 'nanoid';

import ProposalWizardMixin from '@/mixins/ProposalWizardMixin';

export default {
  mixins: [ProposalWizardMixin],
  computed: {
    ...mapGetters({
      message: 'proposalWizard/message',
      isLoaded: 'proposalWizard/isLoaded',
      paymentScheduleItems: 'proposalWizard/paymentScheduleItems',
      subTotal: 'proposalWizard/subTotal',
      state: 'proposalWizard/state',
      pricingAndPaymentInformation: 'proposalWizard/pricingAndPaymentInformation',
      termsConditionsWarranties: 'proposalWizard/termsConditionsWarranties',
    }),
    totalPercentage() {
      let sum = 0;

      for (let item of this.paymentScheduleItems) {
        sum += parseInt(item?.attributes?.percentage) || 0;
      }

      return sum;
    },
    tax() {
      return (this.subTotal / 100) * 13;
    },
    total() {
      return this.subTotal + this.tax;
    },
  },
  methods: {
    ...mapActions({
      megaUpdate: 'proposalWizard/megaUpdate',
      actionAddNewPaymentScheduleItem: 'proposalWizard/addNewPaymentScheduleItem',
      actionDeletePaymentScheduleItem: 'proposalWizard/deletePaymentScheduleItem',
    }),
    ...mapMutations({
      setPaymentScheduleItems: 'proposalWizard/SET_PAYMENT_SCHEDULE_ITEMS',
      setSubTotal: 'proposalWizard/SET_SUB_TOTAL',
      setSubTotalPercentage: 'proposalWizard/SET_SUB_TOTAL_PERCENTAGE',
      setPricingAndPaymentInformation: 'proposalWizard/SET_PRICING_AND_PAYMENT_INFORMATION',
      setTermsConditionsWarranties: 'proposalWizard/SET_TERMS_CONDITIONS_WARRANTIES',
    }),
    // Helpers
    priceFromPercentage(percentage) {
      return (this.subTotal / 100) * percentage;
    },
    priceFromPercentageWithHst(percentage) {
      return ((this.subTotal + this.tax) / 100) * percentage;
    },
    // Actions
    onPercentageChange() {
      this.setSubTotalPercentage(this.totalPercentage);
    },
    duplicatePaymentScheduleItem(attributes) {
      const newAttributes = { ...attributes };
      delete newAttributes.created_at;
      delete newAttributes.updated_at;

      this.actionAddNewPaymentScheduleItem({
        id: nanoid(),
        type: 'payment_schedule_item',
        attributes: newAttributes,
      });
      this.setPaymentScheduleItems(this.paymentScheduleItems);
      this.setSubTotal(this.subTotal);
    },
    deletePaymentScheduleItem(id) {
      this.actionDeletePaymentScheduleItem(id);
      this.setPaymentScheduleItems(this.paymentScheduleItems);
      this.setSubTotal(this.subTotal);
    },
    onPricingAndPaymentInformationUpdate() {
      this.setPricingAndPaymentInformation(JSON.stringify(this.$refs.pricingAndPaymentInformationEditor.getContent()));
    },
    onTermsConditionsWarrantiesUpdate() {
      this.setTermsConditionsWarranties(JSON.stringify(this.$refs.termsConditionsWarrantiesEditor.getContent()));
    },
    // Validations
    updateValidation() {
      if (this.totalPercentage !== 100) {
        this.$scrollTo('#payment-schedule', 400);
      }

      return this.totalPercentage === 100;
    },
  },
};
</script>
